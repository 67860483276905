import { useTheme } from "@emotion/react";
import {
  Box,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormControlLabel,
  IconButton,
  InputBase,
  Slider,
  Typography,
  useMediaQuery,
  Button as ButtonMaterial,
  Tabs,
  Tab,
  TabScrollButton
} from "@mui/material";

import { debounce, min } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// API
import { getAllTypeAttrsByTypeId } from "../../../../api/typeAttrs/getAllTypeAttrs";
import { getCategoryByNameAndType } from "../../../../api/categories/getCategoryByName";
import {
  getProductListings,
  getProductListingsFilters,
} from "../../../../api/product/getListingsByProductType";

import styles from "./styles.module.css";
import { useTranslate } from "../../../../context/translate/translate.context";

// Components
import CategorySeo from "../../../../lib/seo/CategorySeo";
import BreadcrumbsNavigation from "../../../BreadcrumbsNavigation";
import ProductCard from "../../../ProductCard";
import Button from "../../../UI/Button";
import ArrowLeft from "../../../svg/ArrowLeft";
import CupIcon from "../../../svg/Cup";
import SearchIcon from "../../../svg/SearchIcon";
import FilterDropdown from "../../../vendor/DashboardComponents/FilterDropdown";

// Icons
import FiltersSVG from "../../../../lib/svg/filters";
import ArrowDownSVG from "../../../../lib/svg/arrowDown";
import { ClearIcon } from "@mui/x-date-pickers";
import CloseIcon from '@mui/icons-material/Close';

const defaultFilters = {
  limit: 8,
  country: "RU",
  text: "",
  sort: { updatedAt: -1 },
  price: { min: 0, max: 0 },
  //soldOut: [true, false],
  discount: [true, false],
  //reviews: [true, false],
  //instantDelivery: [true, false],
  server: [],
  //software: [],
};

import { styled } from '@mui/system';
import MentupModal from "../../../MentupModal/MentupModal";
import { LoadingButton } from "@mui/lab";

// Custom styled scroll button component
const CustomScrollButton = styled(TabScrollButton)(({ theme, direction }) => ({
  '&.MuiTabScrollButton-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.mode === 'dark' ? '#26262C' : '#8142f64d', // Dark background
    borderRadius: '50%', // Circle shape
    width: '40px',
    height: '40px',
    marginLeft: '10px',
    // Hide scroll button when not in use
    
     // Apply styles based on direction
    ...(direction === 'left' ? {
      left: '0',
      '&.Mui-disabled': {
        display: 'none', // Completely remove the button from layout
      },
      marginRight: '10px', // Offset for visibility
    } : {
      right: '0',
      '&.Mui-disabled': {
        display: 'none', // Completely remove the button from layout
      },
      marginRight: '10px', // Offset for visibility
    }),
    // Hide scroll button on mobile
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide the button on small screens
    },
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.mode === 'dark' ? '#fff' : 'black', // White arrow color
  },
}));

const CustomScrollButtonAttrs = styled(TabScrollButton)(({ theme, direction }) => ({
  '&.MuiTabScrollButton-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.mode === 'dark' ? '#26262C' : '#8142f64d', // Dark background
    borderRadius: '50%', // Circle shape
    width: '40px',
    height: '40px',
    marginLeft: '10px',
    paddingY: '0',
    // Hide scroll button when not in use
    
     // Apply styles based on direction
    ...(direction === 'left' ? {
      left: '0',
      '&.Mui-disabled': {
        display: 'none', // Completely remove the button from layout
      },
      marginRight: '10px', // Offset for visibility
    } : {
      right: '0',
      '&.Mui-disabled': {
        display: 'none', // Completely remove the button from layout
      },
      marginRight: '10px', // Offset for visibility
    }),
    // Hide scroll button on mobile
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide the button on small screens
    },
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.mode === 'dark' ? '#fff' : 'black', // White arrow color
  },
}));

const CategoryProductPage = () => {
  const { slug, typeSlug } = useParams();

  const theme = useTheme();
  const navigate = useNavigate();
  const { translate } = useTranslate();
  
  const [currentPage, setCurrentPage] = useState(0);
  const [category, setCategory] = useState(null);
  const [activeType, setActiveType] = useState(null);
  
  const [loadingCategory, setLoadingCategory] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  
  const [products, setProducts] = useState([]); // Initial empty Array
  const [pages, setTotalPages] = useState(0);
  const [allFilters, setAllFilters] = useState();
  const [priceRadius, setPriceRadius] = useState([0, 0]);
  const [filters, setFilters] = useState(defaultFilters);
  
  const [attrs, setAttrs] = useState([]);
  const [activeAttr, setActiveAttr] = useState(null);
  
  const ref = useRef(null);
  const buttonRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();

  // Useffect to check for attr in the url, if it exists, find the attr and set it as the activeAttr
  useEffect(() => {
    const attr = searchParams.get('attr');

    if(activeType && category){
      // find the index of the 
      const typeIndex = category.productTypes.findIndex(({ _id }) => _id === activeType._id);
      if(typeIndex !== -1) {
        setValue(typeIndex);
      }
    }

    if (attr && slug && activeType && attrs?.length >= 1) {

      // Attribute assign
      const foundAttr = attrs.find(({ slug }) => slug === attr);
      if (foundAttr) {
        setActiveAttr(foundAttr);
        // find the index of the group that the attribute belongs to
        const groupIndex = groups.findIndex(({ _id }) => _id === foundAttr.attributeGroup?._id);
        if(groupIndex !== -1) {
          setAttrValue(groupIndex);
          setSelectedAttr(foundAttr);
        }

      } else {
        setActiveAttr(attrs[0]);
        navigate(`/games/${slug}/${activeType.slug}?attr=all`);
      }

    }
  }, [searchParams, slug, activeType, attrs]);
  
  // Function to handle price change
  const handlePriceChange = (_, newValue) => {
    setCurrentPage(0);
    setFilters((prev) => ({
      ...prev,
      price: { min: newValue[0], max: newValue[1] },
    }));
  };
  
  // Function to handle search
  const handleSearch = useCallback(
    async (f) => {
      if (!activeType?._id) return; // Ensure activeType is set
      if(!category?._id) return;
      setLoadingProducts(true);
      f.attribute = activeAttr?._id;
      f.category = category?._id
      const { data } = await getProductListings(f);
      setProducts((prev) => (f.page ? prev.concat(data.items) : data.items));
      setTotalPages(data.totalPages);
      setLoadingProducts(false);
    },
    [filters.sort, activeType?._id, activeAttr?._id]
  );
  
  // Debounce the search function
  const debouncedSearchText = useMemo(() => debounce(handleSearch, 400), [handleSearch]);
  
  // Function checks the filter list and if the value is already in the list, it removes it
  const checkFilterList = (key, value) => {
    setCurrentPage(0);
    setFilters((prev) => ({
      ...prev,
      [key]: prev[key].includes(value)
        ? prev[key].filter((keyValue) => keyValue !== value)
        : prev[key].concat(value),
    }));
  };
  
  // Function to check the sort
  const checkSort = useCallback(
    async (sort) => {
      setCurrentPage(0);
      setFilters((prev) => ({ ...prev, sort: { price: sort, updatedAt: -1} }));
    },
    [filters.sort]
  );
  
  // Function to get the filters
  const getFilters = async (category, type) => {
    try {
      const { data } = await getProductListingsFilters(category, type);
      setAllFilters(data);
      setPriceRadius([data.price.min, data.price.max]);
      setFilters((prev) => ({
        ...prev,
        server: data.server.map(({ _id }) => _id),
        software: data.software.map(({ _id }) => _id),
        price: data.price,
      }));
    } catch (error) {
      navigate('/404')
    }
  };

  const [groups, setGroups] = useState([]);
  
  // Function to get the type attributes
// Function to get the type attributes
const getTypeAttrs = async (typeId, category) => {
  try {
    const { data } = await getAllTypeAttrsByTypeId(typeId, category._id);

    const grouped = {};

    data.forEach((attr, index) => {
      const groupId = attr.attributeGroup?._id;
      if (groupId) {
        if (!grouped[groupId]) {
          grouped[groupId] = {
            ...attr.attributeGroup,
            attributes: []
          };
        }
        grouped[groupId].attributes.push(attr);
      }
    });

    const organizedGroups = Object.values(grouped);

    // Add "all" group as the first entry
    setGroups([
      {
        _id: '0',
        name: 'Все',
        slug: 'all',
        disabled: false,
        groupType: 'all',
      },
      ...organizedGroups
    ]);

    // remove the entries that dont have a groupType from data
    data.forEach((attr, index) => {
      if (!attr.attributeGroup) {
        data.splice(index, 1);
      }
    });

    // Update attrs state with "all" entry
    setAttrs([
      {
        category: category._id,
        name: 'Все',
        productType: typeId,
        slug: 'all',
        _id: '0'
      },
      ...data
    ]);

    const rangeGroups = organizedGroups.filter(({ groupType }) => groupType === 'range');

    // Apply each range group attributes values to the filters
    rangeGroups.forEach((group) => {
      group.attributes.forEach((attr) => {
        setFilters((prev) => ({
          ...prev,
          [attr.slug]: [attr.valueRange.min, attr.valueRange.max]
        }));
      });
    });

    const type = category?.productTypes.find(({ _id }) => _id === typeId);
    if (slug && type.slug) {
      getFilters(category._id, typeId);
    }
  } catch (error) {
    navigate('/404')
  }
};

  // State to handle loading of next page
  const [loadingNextPage, setLoadingNextPage] = useState(false);

  // Function to handle next page
  const handleNextPage = useCallback(async () => {
    // Exit if already loading or if no more pages
    if (loadingProducts || loadingNextPage || currentPage + 1 >= pages) return;
  
    setLoadingNextPage(true); // Set loading state to true
    setCurrentPage(prevPage => {
      const newPage = prevPage + 1;
      debouncedSearchText({
        ...filters,
        type: activeType?._id,
        page: newPage,
      })
      setLoadingNextPage(false)
      return newPage;
    });
  }, [loadingProducts, loadingNextPage, currentPage, pages, filters, activeType?._id, debouncedSearchText]);
  
  // Function to handle scroll event
  const handleScroll = useCallback(() => {
    if (loadingProducts || loadingNextPage || currentPage + 1 >= pages) return;
  
    const rect = buttonRef.current?.getBoundingClientRect();
    if (rect && rect.top < window.innerHeight && rect.bottom >= 0) {
      handleNextPage();
    }
  }, [loadingProducts, loadingNextPage, currentPage, pages, handleNextPage]);

  // Debounce the scroll event
  const debouncedHandleScroll = useMemo(() => debounce(handleScroll, 300), [handleScroll]);
  
  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);
  
  // Function to get the category data
  const getCategory = async (slug) => {
    try {
      setLoadingCategory(true);
      const res = await getCategoryByNameAndType(slug);
      if (res.status === 200) {
        setCategory(res.data);
        // sort category productTypes, so the one with the slug prochee, then make it to be the last in the array
        category?.productTypes?.sort((a, b) => {
          if(a.slug === 'prochee') {
            return -1;
          }
          return 1;
        });
        const activeType = res.data.productTypes.find(
          ({ slug }) => slug === typeSlug
        );
        setActiveType(activeType);
        if (activeType?._id && res.data._id) {
          //getFilters(res.data._id, activeType._id);
          await getTypeAttrs(activeType._id, res.data);
        }
      }
    } catch (error) {
      navigate('/404')
    } finally {
      setLoadingCategory(false);
    }
  };

  // Fetch category data on component mount
  useEffect(() => {
    if (slug) {
      getCategory(slug);
    }
  }, [slug]);
  
  // Fetch products on category and type change
  useEffect(() => {
    if (activeType && allFilters) {
      debouncedSearchText({ ...filters, type: activeType._id });
    }
  }, [activeType, allFilters, filters]);
  
  // Fetch products on attribute change and change the activeType, also reset the page
  const handleTypeClick = useCallback(
    async (type) => {
      setFilters(defaultFilters)
      setProducts([]);
      setActiveType(type);
      setCurrentPage(0);
      setAttrs([]);
      setActiveAttr(null);
      setSelectedAttr(null);
      // reset filters to default
      navigate(`/games/${slug}/${type.slug}?attr=all`);
      if(type?._id && category?._id) {
        await getTypeAttrs(type._id, category);
      }
    },
    [category?._id, slug]
  );

  const [openGroup, setOpenGroup] = useState(false);

  const [activeGroup, setActiveGroup] = useState(null);

  const [selectedAttr, setSelectedAttr] = useState(null);

  // Fetch products on attribute change and change the activeAttr, also reset the page
  const handleActiveAttrChange = useCallback(
    (attr) => {
      setProducts([]);
      setActiveAttr(attr);
      setCurrentPage(0);
      if(attr?.slug === 'all') {
        setAttrValue(0)
      }
      navigate(`/games/${slug}/${activeType.slug}?attr=${attr?.slug}`);
      getFilters(category._id, activeType._id);
    }
    ,[activeType?._id, category?._id, activeType?.slug, slug]
  );

  const handleCloseModal = () => {
    setOpenGroup(false);
    setActiveGroup(null);
  }

  const handleActiveGroup = useCallback(
    (group) => {
      if (group.groupType === 'range' || group.groupType === 'single') {
        setActiveGroup(group);
        setOpenGroup(true);
      } else {
        handleActiveAttrChange(group)
      }
    }, [activeType, category]);
  
  // Render the info container
  const renderInfoContainer = ({ title, description }, index) => (
    <Box key={index} className={styles.infoItem}>
      <Typography variant="body1" className={styles.infoItemDescription}>
        {description}
      </Typography>
    </Box>
  );
  
  // Check if the user is on a mobile device
  const isMobile = useMediaQuery("@media (max-width: 900px)");

  // function to make every word in a string start with a capital letter
  const capitalize = (str) => {
    return str
      .toLowerCase() // Convert the entire string to lowercase first
      .split(' ') // Split the string into an array of words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a single string
  };

  const [value, setValue] = useState(0);

  const [attrValue, setAttrValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeAttr = (event, newValue) => {
    setAttrValue(newValue);
  }

  const handleValueRangeChange  = (attr) => (_, newValue) => {
    setCurrentPage(0);
    setFilters((prev) => ({
      ...prev,
      [attr.slug]: newValue
    }));
  }

  return (
    <Container className={styles.container}>
      {/* BreadCrumbs */}
      {category && activeType && (
        <BreadcrumbsNavigation
          breadcrumbs={[
            { label: "navigation.home", path: "/" },
            { label: "navigation.games", path: "/games" },
            { label: category.name, path: "/games/" + category.slug },
            { label: activeType?.name },
          ]}
        />
      )}

      {/* SEO */}
      {category && activeType && (
        <CategorySeo category={category} activeType={activeType} />
      )}

      {/* Category Types All List */}
      <Box className={styles.descriptionContainer}>
        <Typography variant="h1" className={styles.title}>
          {activeType?.name} {category?.name}
        </Typography>

        {/* Product Types */}

        {!!category?.productTypes?.length && (
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            components={{
              TabScrollButton: (props) => <CustomScrollButton {...props} direction={props.direction} />,
            }}
            ScrollButtonComponent={(props) => <CustomScrollButton {...props} direction={props.direction} />}
            sx={{ 
              '& .MuiTabs-indicator': { display: 'none' },
            }}
          >
            {category.productTypes.map((type, index) => (
              <Tab 
                key={index} 
                onClick={() => handleTypeClick(type)}
                label={type.name} 
              />
            ))}
          </Tabs>
        )}

        {/* Attributes */}

        {!!groups?.length && groups.length > 1 && (
          <Tabs
            value={attrValue}
            onChange={handleChangeAttr}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            components={{
              TabScrollButton: (props) => <CustomScrollButtonAttrs {...props} direction={props.direction} />,
            }}
            ScrollButtonComponent={(props) => <CustomScrollButtonAttrs {...props} direction={props.direction} />}
            sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
          >
            {groups.map((group, index) => (
              <Tab 
                key={index} 
                onClick={
                  () => handleActiveGroup(group)
                }
                label={group.name} 
                sx={{
                  visibility: group.slug === 'all'? 'hidden' : 'visible',
                  height: group.slug === 'all' ? '0' : 'auto',
                  width: group.slug === 'all' ? '0' : 'auto',
                  padding: group.slug === 'all' ? '0' : 'auto',
                  // if second element then remove margin left
                  // remove margin left for the second element
                  '&:nth-of-type(2)': {
                    marginLeft: '0'
                  }
                }}
              />
            ))}
          </Tabs>
        )}

      </Box>
      
      {/* Filters + Search */}
      <Box className={styles.filtersContainer}>
        <Box className={styles.nowrap}>
          <FiltersSVG
            color={theme.palette.mode === "dark" ? "#f6f6f6" : "#1f1d22"}
          />

          <Box className={styles.filters}>
            <FilterDropdown title="vendorDashboard.filters.price.title">
              <Box className={styles.priceFilter}>
                <Typography
                  color="primary"
                  id="range-slider"
                  sx={{ width: "50px" }}
                >
                  {filters.price.min}
                </Typography>

                <Slider
                  min={priceRadius[0]}
                  max={priceRadius[1]}
                  getAriaLabel={() => "Price range"}
                  value={[filters.price.min, filters.price.max]}
                  onChange={handlePriceChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={() => "Price range"}
                />

                <Typography
                  color="primary"
                  id="range-slider"
                  w
                  sx={{ width: "50px" }}
                >
                  {filters.price.max}
                </Typography>
              </Box>
            </FilterDropdown>
            
            <FilterDropdown title="vendorDashboard.filters.discount.title">
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={true}
                      color="primary"
                      onChange={() => checkFilterList("discount", true)}
                      checked={filters.discount.includes(true)}
                    />
                  }
                  label={translate("vendorDashboard.filters.discount.yes")}
                />
              </Box>
              <Box className={styles.categoryItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={false}
                      color="primary"
                      onChange={() => checkFilterList("discount", false)}
                      checked={filters.discount.includes(false)}
                    />
                  }
                  label={translate("vendorDashboard.filters.discount.no")}
                />
              </Box>
            </FilterDropdown>
            {filters?.server?.length > 1 && (
              <FilterDropdown title="vendorDashboard.filters.server">
                {allFilters?.server.map(({ _id, name }) => (
                  <Box key={_id} className={styles.categoryItem}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={_id}
                          color="primary"
                          onChange={({ target }) =>
                            checkFilterList("server", target.value)
                          }
                          checked={filters.server.includes(_id)}
                        />
                      }
                      label={name}
                    />
                  </Box>
                ))}
              </FilterDropdown>
            )}
          </Box>
        </Box>

        <ButtonMaterial 
          color="secondary"
          className={styles.buttonMaterial}
          onClick={() => checkSort(filters.sort.price === -1 ? 0 : -1)}
        >
          <Typography sx={{
            fontSize: '14px'
          }}>
            {translate("vendorDashboard.filters.price.title")+ ' ' + (filters.sort.price === -1 ? translate("vendorDashboard.filters.price.asc") : translate("vendorDashboard.filters.price.desc"))}
          </Typography>
          <ArrowDownSVG
            color={theme.palette.mode === "dark" ? "#f6f6f6" : "#1f1d22"}
            isRotated={filters.sort.price === -1 ? true : false}
          />
        </ButtonMaterial>

        <Box className={styles.inputContainer}>
          <InputBase
            ref={ref}
            className={styles.input}
            value={filters.text}
            onChange={({ target }) => {
              setCurrentPage(0);
              setFilters((prev) => ({ ...prev, text: target.value }));
            }}
            placeholder={translate("header.searchCategoryProduct")}
          />
          <IconButton
            className={styles.iconContainer}
            aria-label="clear search input"
            onClick={() => setFilters((prev) => ({ ...prev, text: "" }))}
            disabled={!filters.text}
          >
            {filters.text ? <ClearIcon /> : <SearchIcon />}
          </IconButton>
        </Box>
        
      </Box>

      {/* Products Grid */}

      <Box className={styles.productsContainer}>
        {!products.length && loadingProducts ? (
          <CircularProgress
            size={64}
            color="primary"
            sx={{ marginTop: theme.spacing(5) }}
          />
        ) : (
          <>
            {products.map((product) => (
              <ProductCard 
                key={product._id} 
                product={product} 
                variant={isMobile ? "large" : "small"}
              />
            ))}
          </>
        )}
      </Box>

      <Button
        ref={buttonRef}
        className={styles.button}
        fullWidth
        loading={loadingCategory || loadingProducts}
        onClick={handleNextPage}
      >
        {translate("catalog.button")}{" "}
        <Box sx={{ transform: "rotate(270deg)", ml: "4px" }}>
          <ArrowLeft width={18} height={17} />
        </Box>
      </Button>

      {/* Divider */}
      <Box className={styles.divider} />

      {/* SEO Image + Text */}
      <Box className={styles.appInfo}>
        <Box className={styles.seoInfo}>
          <Card
            className={styles.banner}
            sx={{
              backgroundImage: `url(${
                category?.banner || ""
              })`,
            }}
          />
        </Box>

        <Box className={styles.platformInfoTitleContainer}>
          <CupIcon
            color={theme.palette.mode === "dark" ? "#EDF67D" : "#8142f6"}
          />
          {category?.name && (
            <Typography className={styles.infoTitle}>
              {translate("games.info.title", { title: category?.name })}{" "}
              <span>Mentup</span>
            </Typography>
          )}
        </Box>
        {Array.from({ length: 1 }, () => ({
          description: category?.description 
        })).map(renderInfoContainer)}
      </Box>

      <MentupModal open={openGroup} close={handleCloseModal}
        sx={{
          minWidth: '350px',
          maxWidth: '350px',
          // if media mobile 900px max, then minWidth is 90%
          '@media (max-width: 900px)': {
            minWidth: '80%',
            maxWidth: '80%',
          },
        }}
      >
        {activeGroup && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            
          }}>
          <Box className={styles.modalHeader}>
            <Box>
              <Typography 
                className={styles.modalHeaderReset}
                onClick={() => 
                  (
                    setSelectedAttr(null),
                    setFilters(defaultFilters)
                  )
                }
              >
                {capitalize('Сбросить')}
              </Typography>
            </Box>
            <Typography className={styles.modalHeaderTitle}>
                {capitalize(activeGroup.name)}
            </Typography>
            <IconButton
                onClick={handleCloseModal}
            >
                <CloseIcon sx={{
                    fontSize: '26px',
                    color: 'gray',
                }} />
            </IconButton>
          </Box>
          <Box className={styles.modalContent}>
            <Box className={styles.modalContentItems}>

              {activeGroup.groupType === 'single' && activeGroup.attributes.map((attr) => (
                <Chip
                  key={attr._id}
                  className={styles.modalContentItem}
                  label={capitalize(attr.name)}
                  onClick={() => setSelectedAttr(attr)}
                  sx={{
                    backgroundColor: attr.slug === selectedAttr?.slug
                      ? "#8142f6 !important"
                      : "",
                    "& span": {
                      color:
                        theme.palette.mode === "light" &&
                        attr.slug === selectedAttr?.slug
                          ? "white !important"
                          : "",
                    },
                  }}
                />
              ))}

              {activeGroup.groupType === 'range' && activeGroup.attributes.map((attr) => (
                  <Box key={attr._id} className={styles.modalContentItemRange}>
                    <Typography sx={{
                      align: 'left'
                    }}>
                      {capitalize(attr.name)}
                    </Typography>
                    <Box sx={{
                      paddingX: '20px'
                    }} >
                      <Slider
                        min={attr.valueRange.min}
                        max={attr.valueRange.max}
                        getAriaLabel={() => capitalize(attr.name)+" Range"}
                        value={[filters[attr.slug]?.[0] || 0, filters[attr.slug]?.[1] || 100]} // Current selected range
                        onChange={handleValueRangeChange(attr)}
                        valueLabelDisplay="auto"
                        getAriaValueText={() => capitalize(attr.name)+" Range"}
                        
                      />
                    </Box>
                  </Box>
              ))}
            </Box>
          </Box>
          <LoadingButton
            className={styles.button2}
            onClick={() => (
              handleActiveAttrChange(selectedAttr ? selectedAttr : (groups[0])),
              setOpenGroup(false)
            )}
          >
              <Typography>Применить</Typography>
          </LoadingButton>
          </Box>
        )}
      </MentupModal>
    </Container>
  );
};

export default CategoryProductPage;
