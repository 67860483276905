import { AccordionSummary, Box, Typography } from "@mui/material";
import clsx from "clsx";
import { useCallback } from "react";

import { isoToReadableDate } from "../../../../../../lib/isoToReadableDate";
import ArrowDownSVG from "../../../../../../lib/svg/arrowDown";
import Button from "../../../../../UI/Button";
import { TranslateText } from "../../../../../translate-text";
import styles from "./styles.module.css";

const TransactionItemHeader = ({
  item,
  opened,
  dimensions,
  setDimensions,
  open,
}) => {
  const onDimensionDetect = useCallback(
    (status) => (ref) => {
      if (!!ref?.offsetWidth && dimensions[status] !== ref?.offsetWidth) {
        setDimensions((prev) => ({
          ...prev,
          [status]: Math.max(ref.offsetWidth, prev[status]),
        }));
      }
    },
    [...Object.values(dimensions)]
  );

  const currencySign = (currency) => {
    if (currency === "USD") {
      return "$";
    }

    return "₽";
  };

  console.log(item.status)

  return (
    <AccordionSummary className={styles.accordion}>
      <Box className={styles.summary}>
        <Box className={styles.summaryItem}>
          <Box className={styles.headerItem}>
            <Typography className={styles.headerItemTitle}>
              <TranslateText value="transactions.items.header.id" />
            </Typography>
            <Typography
              ref={onDimensionDetect("id")}
              sx={{ minWidth: `${dimensions.id}px` }}
              className={styles.id}
            >
              {item._id}
            </Typography>
          </Box>
          <Box className={styles.headerItem}>
            <Typography className={styles.headerItemTitle}>
              <TranslateText value="transactions.items.header.date" />
            </Typography>
            <Typography
              ref={onDimensionDetect("date")}
              sx={{ minWidth: `${dimensions.date}px` }}
              className={styles.date}
            >
              {isoToReadableDate(item.createdAt)}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.summaryItem}>
          <Box className={styles.headerItem}>
            <Typography className={styles.headerItemTitle}>
              <TranslateText value="transactions.items.header.status" />
            </Typography>
            <Box
              className={styles.statusTag}
              sx={{
                backgroundColor:
                  item.status === "succeeded" ? "#12B76A1A" : "#F1FF5217",
              }}
            >
              <Box
                className={styles.dot}
                sx={{
                  backgroundColor:
                    item.status === "succeeded" ? "#1db870" : "#FF5252",
                }}
              />
              <Typography
                sx={{
                  color:
                    item.status === "succeeded"
                      ? "#1db870 !important"
                      : "#FF5252 !important",
                }}
              >
                <TranslateText
                  value={`vendorDashboard.filters.status.${item.status}`}
                />
              </Typography>
            </Box>
            <Typography
              ref={onDimensionDetect("status")}
              className={styles.status}
              sx={{ 
                minWidth: `${dimensions.status}px`, 
                color: (() => {
                  switch (item.status) {
                    case 'succeeded':
                      return '#66bb6a';
                    case 'pending':
                      return 'primary.yellow';
                    default:
                      return '#FF5252';
                  }
                })()
              }}
              
            >
              <TranslateText
                value={`vendorDashboard.filters.status.${item.status}`}
              />
            </Typography>
          </Box>
          <Box className={styles.headerItem}>
            <Typography className={styles.headerItemTitle}>
              <TranslateText value="transactions.items.header.type" />
            </Typography>
            <Typography
              ref={onDimensionDetect("type")}
              sx={{ minWidth: `${dimensions.type}px` }}
              className={styles.type}
            >
              {item.type}
            </Typography>
          </Box>
          <Box className={styles.headerItem}>
            <Typography className={styles.headerItemTitle}>
              <TranslateText value="transactions.items.header.paidWith" />
            </Typography>
            <Typography
              ref={onDimensionDetect("paidWith")}
              sx={{ minWidth: `${dimensions.paidWith}px` }}
              className={styles.paidWith}
            >
              {item.paidWith}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.summaryItem}>
          <Box className={styles.headerItem}>
            <Typography className={styles.headerItemTitle}>
              <TranslateText value="transactions.items.header.sum" />
            </Typography>
            <Typography
              ref={onDimensionDetect("sum")}
              sx={{ minWidth: `${dimensions.sum}px`, textAlign: "right" }}
              className={styles.sum}
            >
              {item.total} {currencySign(item.currency)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Button
        className={clsx(styles.detailsButton, opened && styles.rotateSVG)}
        onClick={open}
      >
        <Typography className={styles.detailsButtonText}>
          <TranslateText value="transactions.items.header.view" />
        </Typography>
        <ArrowDownSVG />
      </Button>
    </AccordionSummary>
  );
};

export default TransactionItemHeader;
