import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { getSeoCategoryPage } from '../../api/seo/getSeoCategoryPage';

// Product Helmet for Facebook and Twitter
const CategorySeo = ({activeType, category}) => {

    const defaultSeo = {
        // Meta
        metaTitle: 'MentUp Store',
        metaDescription: 'Mentup - Online Gaming Marketplace Selling in-game items, skins, accounts, boosting services and more.',
        metaKeywords: 'mentup, mentup marketplace, mentup store, mentup gaming, mentup gaming marketplace, mentup gaming store',
        // Graph
        graphTitle: 'MentUp Marketplace',
        graphDescription: 'MentUp - Online Gaming Marketplace Selling in-game items, skins, accounts, boosting services and more.',
        graphType: 'Website',
        graphUrl: 'https://mentup-store.com/',
    }

    const [seoData, setSeoData] = useState(null);
    const [loaded, setLoaded] = useState(true);

    // Get SEo Category
    useEffect(() => {
        if(activeType && category) {
            const getSeoCategory = async () => {
                setLoaded(false);
                await getSeoCategoryPage(category.name, activeType.name)
                .then((res) => {
                    const seoCategory = res.data;
                    console.log(res.data)
                    if(seoCategory) {
                        setSeoData({
                            // Meta
                            metaTitle: seoCategory.metaTitle,
                            metaDescription: seoCategory.metaDescription,
                            metaKeywords: seoCategory.metaKeywords,
                            // Graph
                            graphTitle: seoCategory.graphTitle,
                            graphDescription: seoCategory.graphDescription,
                            graphType: seoCategory.graphType,
                            graphUrl: seoCategory.graphUrl,
                        })
                    }
                })
                .catch((err) => {
                    setSeoData(defaultSeo);
                })
                .finally(() => {
                    setLoaded(true);
                })
            }
            getSeoCategory();
        }
    }, [activeType, category]);

    return (
        <>
        {loaded && seoData && (
            <Helmet>
                { /* Standard metadata tags */ }
                <title>{seoData.metaTitle}</title>
                <meta name="description" content={seoData.metaDescription} />
                { /* End standard metadata tags */ }

                { /* Keywords */ }
                <meta name="keywords" content={seoData.metaKeywords} />
                { /* End Keywords */ }

                { /* Open Graph tags */ }
                <meta property="og:type" content={seoData.graphType} />
                <meta property="og:title" content={seoData.graphTitle} />
                <meta property="og:description" content={seoData.graphDescription} />
                <meta property="og:url" content={seoData.graphUrl} />
                { /* End Open Graph tags */ }

            </Helmet>
        )}
        </>
    )
}

export default CategorySeo;