import Cookies from "js-cookie";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import { verifyToken } from "../api/user/verifyToken";
import AboutUs from "../components/aboutUs-page/AboutUs";
import { Error404 } from "../components/auth/404/Erorr404";
import Login from "../components/auth/Login";
import Logout from "../components/auth/Logout";
import Register from "../components/auth/Register";
import BlogHome from "../components/blog//BlogHome/BlogHome.jsx";
import BlogPost from "../components/blog/BlogPost/BlogPost";
import CartPage from "../components/cart-page/CartPage";
import CheckoutSuccess from "../components/cart-page/CheckoutSuccess";
import Catalog from "../components/catalog-page/Catalog/index.jsx";
import { Chat } from "../components/chat-page/Chat";
import ContactPage from "../components/contact-page/ContactPage";
import { FaqPage } from "../components/faq-page/FaqPage";
import AgencyContract from "../components/info/laws/agency-contract";
import LicenseAgreement from "../components/info/laws/license-agreement";
import PrivacyPolicy from "../components/info/laws/privacy-policy";
import Rules from "../components/info/laws/rules";
import Payments from "../components/info/payments";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import NotificationsPage from "../components/notifications-page/NotificationsPage";
import OrderPage from "../components/orders/OrderPage";
import OrderPageBuyer from "../components/orders/OrderPageBuyer";
import PaymentSuccess from "../components/paymentSuccess-page/paymentSuccess.jsx";
import Privacy from "../components/Privacy";
import Search from "../components/search-page/Search";
import SellersPage from "../components/sellers-page";
import Terms from "../components/Terms";
import TermsOfSale from "../components/TermsOfSale";
import { Transfer } from "../components/transfer-page/index.jsx";
import CategoryPage from "../components/user/category/CategoryPage";
import HomePage from "../components/user/Home";
import EditProductCodes from "../components/user/products/EditProductCodes";
import ProductPage from "../components/user/products/ProductPage";
import Profile from "../components/user/Profile";
import VendorProfile from "../components/vendor/VendorProfile";
import { useSocketProvider } from "../context/socket";
import { resetUser } from "../store/userStore";
import CategoryProductPage from "../components/user/category/CategoryProductPage/index.jsx";
import GiveAway from "../components/giveaway-page/index.jsx";
import ProhibitedProducts from "../components/info/laws/products/index.jsx";
import reviewsPage from "../components/reviewsPage/index.jsx";

const checkAuth = (Component, disableFbAuthentication) => {
  const AuthenticatedComponent = (props) => {
    const socket = useSocketProvider();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    // Clear user data
    const clearUser = async () => {
      Cookies.remove("refreshToken");
      localStorage.removeItem("user");
      localStorage.removeItem("userSettings")
      dispatch(resetUser());
    };
    // Verify user token
    const verifyUser = async () => {
      try {
        const res = await verifyToken();
        // if res.data.status === 'banned' then logout
        if (res?.data?.status === 'banned') {
          clearUser();
          navigate("/");
        }
        if (!res?.data) {
          clearUser();
          navigate("/login");
        } else if (!disableFbAuthentication) {
          socket.authenticate(Cookies.get("refreshToken"));
        }
      } catch {
        clearUser();
        navigate("/");
        window.location.reload();
      }
    };
    // Check if user is logged in
    useEffect(() => {

      const params = new URLSearchParams(window.location.search); 
      if(params?.size > 0) {
        const invitedFrom = params.get('invite');
        if(invitedFrom) {
          localStorage.setItem("hasInvite", JSON.stringify({
            fromUser: invitedFrom 
          }))
        }
      }
      // Get Token and to check if loggedIn
      const token = Cookies.get("refreshToken");
      if (token) {
        verifyUser();
      } else {
        clearUser();
      }
    }, []);
    // Return component
    return <Component {...props} />;
  };
  // Return component
  return AuthenticatedComponent;
};

const checkNotAuthenticated = (Component, disableFbAuthentication) => {
  const NotAuthenticatedComponent = (props) => {
    const navigate = useNavigate();

    const goToUser = async () => {
      const sessionUser = JSON.parse(localStorage.getItem("user"));
      if (!sessionUser) {
        return;
      }

      const res = await verifyToken();
      if (res.data.slug) {
        navigate(`/profile/${res.data.slug}`);
      }
    };

    useEffect(() => {
      const token = Cookies.get("refreshToken");
      if (token) {
        goToUser(token);
      }
    }, []);

    return <Component {...props} />;
  };

  return NotAuthenticatedComponent;
};

const requireAuth = (Component, allowedRoles) => {
  const AuthenticatedComponent = (props) => {
    const socket = useSocketProvider();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const clearUser = async () => {
      Cookies.remove("refreshToken");
      localStorage.removeItem("user");
      dispatch(resetUser());
    };

    const verifyUser = async () => {
      try {
        const res = await verifyToken();
        const user = JSON.parse(localStorage.getItem("user"));
        if (!allowedRoles.includes(user.role)) {
          navigate("/");
        }
        if (!res?.data) {
          clearUser();
          navigate("/");
          window.location.reload();
        } else {
          socket.authenticate(Cookies.get("refreshToken"));
        }
      } catch {
        clearUser();
        navigate("/");
        window.location.reload();
      }
    };

    useEffect(() => {
      const token = Cookies.get("refreshToken");
      if (token) {
        verifyUser();
      } else {
        clearUser;
        navigate("/");
      }
    }, [navigate]);
    return <Component {...props} />;
  };
  return AuthenticatedComponent;
};

const AuthenticatedUserDashboard = requireAuth(["admin"]);
const AuthenticatedUserProfile = requireAuth(Profile, [
  "admin",
  "buyer",
  "vendor",
]);

const CheckAuthHome = checkAuth(HomePage);
const CheckAuthProductPage = checkAuth(ProductPage);
const CheckAuthProductCode = checkAuth(EditProductCodes);
// Category
const CheckAuthCategoryPage = checkAuth(CategoryPage);
const CheckAuthCategoryProductPage = checkAuth(CategoryProductPage)

const CheckAuthVendorProfile = checkAuth(VendorProfile);
const CheckAuthLogout = checkAuth(Logout);
const CheckAuthLogin = checkNotAuthenticated(Login);
const CheckAuthRegister = checkNotAuthenticated(Register);
const CheckAuthRegisterToken = checkNotAuthenticated(Register, true);

const CheckAuthErorr404 = checkAuth(Error404);
const CheckAuthPaymentSuccess = checkAuth(PaymentSuccess);

const CheckAuthCheckout = checkAuth(CartPage);

const CheckAuthAlerts = checkAuth(NotificationsPage);
const CheckAuthFaq = checkAuth(FaqPage);
const CheckAuthOrder = checkAuth(OrderPage);
const CheckAuthBuyerOrder = checkAuth(OrderPageBuyer);
const CheckAuthContact = checkAuth(ContactPage);
const CheckAuthSearch = checkAuth(Search);

const CheckAuthChat = checkAuth(Chat);
const CheckAuthTransfer = checkAuth(Transfer);

const CheckAuthCheckoutSuccess = checkAuth(CheckoutSuccess);
const CheckAuthPrivacy = checkAuth(Privacy);
const CheckAuthTermsOfSale = checkAuth(TermsOfSale);
const CheckAuthTerms = checkAuth(Terms);

const CheckAuthBlog = checkAuth(BlogHome);
const CheckAuthBlogPost = checkAuth(BlogPost);
const CheckAuthSoftwarePage = checkAuth(CategoryPage);
const CheckAuthGamesPage = checkAuth(Catalog);
// Laws
const CheckAuthAgencyContract = checkAuth(AgencyContract);
const CheckAuthLicenseAgreement = checkAuth(LicenseAgreement);
const CheckAuthPrivacyPolicy = checkAuth(PrivacyPolicy);
const CheckAuthRules = checkAuth(Rules);
const CheckAuthProhibitedProducts = checkAuth(ProhibitedProducts)
// General info
const CheckAuthPayments = checkAuth(Payments);
// Sellers
const CheckAuthSellers = checkAuth(SellersPage);
const CheckAuthAboutUs = checkAuth(AboutUs);
// GiveAway
const CheckAuthGiveAway = checkAuth(GiveAway)
// Reviews
const CheckAuthReviews = checkAuth(reviewsPage)

export const AppRouter = () => {
  const ROUTS = [
    {
      path: "/",
      element: <CheckAuthHome />,
      exact: true,
    },
    {
      path: "/logout",
      element: <CheckAuthLogout />,
      exact: true,
    },
    {
      path: "/login",
      element: <CheckAuthLogin />,
      exact: true,
    },
    /*
    {
      path: "/register",
      element: <CheckAuthRegister />,
      exact: true,
    },
    {
      path: "/register/:token",
      element: <CheckAuthRegisterToken />,
      exact: true,
    }, */
    {
      path: "/profile",
      element: <AuthenticatedUserProfile />,
      exact: true,
    }, // User Profile (Protected)
    {
      path: "/profile/:userSlug",
      element: <CheckAuthVendorProfile />,
      exact: true,
    }, // User Profile (Public)
    {
      path: "/profile/:userSlug/:slug",
      element: <CheckAuthProductPage />,
      exact: true,
    }, // Seller Product Page (Public)
    {
      path: "/chat",
      element: <CheckAuthChat />,
      exact: true,
    },
    {
      path: "/chat/:id",
      element: <CheckAuthChat />,
      exact: true,
    },
    {
      path: "/payment/transfer/:code",
      element: <CheckAuthTransfer />,
      exact: true,
    },
    // All reviews page
    {
      path: "/reviews",
      element: <CheckAuthReviews />,
      exact: true,
    },
    /*
    {
      path: "/product/:id",
      element: <CheckAuthProductPage />,
      exact: true,
    }, // Product/category (public)
    */
    {
      path: "/productCode/:id",
      element: <CheckAuthProductCode />,
      exact: true,
    },
    /*
    {
      path: "/products",
      element: <AuthenticatedUserProfile />,
      exact: true,
    },
    */
    // Category catalogue
    {
      path: "/games",
      element: <CheckAuthGamesPage />,
      exact: true,
    },
    // Category page single
    {
      path: "/games/:slug",
      element: <CheckAuthCategoryPage />,
      exact: true,
    },
    // Category product page
    {
      path: "/games/:slug/:typeSlug",
      element: <CheckAuthCategoryProductPage />,
      exact: true,
    },
    {
      path: "/software",
      element: <CheckAuthSoftwarePage />,
      exact: true,
    },
    {
      path: "/software/:slug",
      element: <CheckAuthSoftwarePage />,
      exact: true,
    },
    {
      path: "/checkout",
      element: <CheckAuthCheckout />,
      exact: true,
    },
    {
      path: "/checkout/success",
      element: <CheckAuthPaymentSuccess />,
      exact: true,
    },
    {
      path: "/order/:id",
      element: <CheckAuthOrder />,
      exact: true,
    },
    {
      path: "/orderb/:id",
      element: <CheckAuthBuyerOrder />,
      exact: true,
    },
    {
      path: "/alerts",
      element: <CheckAuthAlerts />,
      exact: true,
    },
    // Info
    {
      path: "/info/payments",
      element: <CheckAuthPayments />,
      exact: true,
    },
    {
      path: "/info/about",
      element: <CheckAuthFaq />,
      exact: true, // NOT DONE
    },
    {
      path: "/info/collab",
      element: <CheckAuthFaq />,
      exact: true, // NOT DONE
    },
    {
      path: "/contact",
      element: <CheckAuthContact />,
      exact: true,
    },
    // Laws
    {
      path: "/info/laws/license-agreement",
      element: <CheckAuthLicenseAgreement />,
      exact: true,
    },
    {
      path: "/info/laws/agency-contract",
      element: <CheckAuthAgencyContract />,
      exact: true,
    },
    {
      path: "/info/laws/privacy-policy",
      element: <CheckAuthPrivacyPolicy />,
      exact: true,
    },
    {
      path: "/info/laws/rules",
      element: <CheckAuthRules />,
      exact: true,
    },
    {
      path: "/info/laws/products",
      element: <CheckAuthProhibitedProducts />,
      exact: true,
    },
    // End Laws

    // Support
    {
      path: "/support/",
      element: <CheckAuthFaq />,
      exact: true, // NOT DONE
    },
    // End Support
    // End Info
    {
      path: "/search",
      element: <CheckAuthSearch />,
      exact: true,
    },  // Search
    {
      path: "/search/:query",
      element: <CheckAuthSearch />,
      exact: true,
    },  // Search query
    {
      path: "/sellers",
      element: <CheckAuthSellers />,
      exact: true,
    }, // sellers page
    {
      path: "/about-us",
      element: <CheckAuthAboutUs />,
      exact: true,
    }, // about us
    {
      path: "/blog/",
      element: <CheckAuthBlog />,
      exact: true,
    }, // Blog
    {
      path: "/blog/:id",
      element: <CheckAuthBlogPost />,
      exact: true,
    }, // Blog Post
    {
      path: "/giveaway",
      element: <CheckAuthGiveAway />,
      exact: true,
    }, // Blog Post
    {
      path: "/404",
      element: <CheckAuthErorr404 />,
      exact: true,
    }, // 404 Route handler + Admin
    {
      path: "*",
      element: <Navigate to="/404" replace />,
      replace: true,
    }, // 404 Route handler + Admin
    {
      path: "/admin/*",
      element: <AuthenticatedUserDashboard />,
      exact: false,
    }, // 404 Route handler + Admin
  ];

  const [loading, setLoading] = useState(false);

  const routes = (
    <Suspense fallback={<Loader />}>
      <Routes onStart={() => setLoading(true)} onEnd={() => setLoading(false)}>
        {ROUTS.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={route.element}
            exact={route.exact}
            replace={route.replace}
          />
        ))}
      </Routes>
    </Suspense>
  );

  return (
    <Router>
      <Layout loading={loading}>{routes}</Layout>
    </Router>
  );
};
