import { Box, Card, Chip, Container, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  getAllCategoriesHome,
  getCategoriesCount,
} from "../../../api/categories/getAllCategories";
import { useTranslate } from "../../../context/translate/translate.context";
import BreadcrumbsNavigation from "../../BreadcrumbsNavigation";
import Button from "../../UI/Button";
import styles from "./styles.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@emotion/react";

const Catalog = () => {
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const [total, setTotal] = useState(0);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  const theme = useTheme()

  const handleNavigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const getGames = async () => {
      try {
        const { data } = await getAllCategoriesHome({ limit: 84 });

        const {
          data: { total },
        } = await getCategoriesCount();

        // multiply data by 8 times

        setGames(data);
        setTotal(total);
      } catch (error) {
        //console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getGames();
  }, []);

  const handleButtonClick = async () => {
    try {
      setLoading(true);

      const { data } = await getAllCategoriesHome({
        limit: 84,
        skip: games.length,
      });

      setGames([...games, ...data]);
    } catch (error) {
      //console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
       <Box sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          marginTop: '20px',
          marginLeft: '2%'
        }}>
          <ArrowBackIcon sx={{ 
              cursor: "pointer", 
              border: '1px solid black' ,
              borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
              borderRadius: '50%',
              padding: '6px',
              transition: '1s all easy-in-out',
          }} onClick={handleNavigateBack} />
        <BreadcrumbsNavigation
          breadcrumbs={[
            { label: "navigation.home", path: "/" },
            { label: "navigation.catalog" },
          ]}
          from={'catalog'}
        />
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '30px'
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '50px'
        }}>
          <Typography sx={{
            fontSize: '26px'
          }}>
            Каталог игр
          </Typography>
        </Box>
      </Box>
      <Box className={styles.container}>
        <Box>
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px',
              marginTop: '10px',
              rowGap: '20px',
              justifyContent: 'center'
            }}>
            {games.map(({ name, slug, primaryProductType, productTypes, media, thumb }, index) => (
                <Box 
                  key={index} 
                  className={styles.card2}
                  onClick={() => navigate(`/games/${slug}`)}
                >
                  <Box
                    className={styles.image2}
                    component="img"
                    src={thumb ? thumb : media}
                    loading="lazy"
                  />
                  <Typography className={styles.title} variant="h4">
                    {name}
                  </Typography>
                </Box>
              )
            )}
          </Box>
          {games.length < total && (
            <Button
              className={styles.button}
              fullWidth
              loading={loading}
              onClick={handleButtonClick}
            >
              {translate("catalog.button")}
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Catalog;
