import {
  Box,
  Button,
  ClickAwayListener,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { deleteProductSeller } from "../../../../api/product/deleteProduct";
import { disableProduct } from "../../../../api/product/disableEnableProduct";
import { useTranslate } from "../../../../context/translate/translate.context";
import MenuDots from "../../../svg/Product";
import DeleteConfirmContent from "./components/DeleteConfirmContent";
import HideItemConfirmContent from "./components/HideItemConfirmContent";
import PromoteProductContent from "./components/PromoteProductContent";
import { ProductMenuOptions } from "./constants";
import styles from "./styles.module.css";
import AddProductModal from "../../../vendor/DashboardComponents/AddProductModal";

const MenuOptions = ({
  productId,
  productName,
  isHidden,
  onDelete,
  onHide,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { translate } = useTranslate();

  const [showEdit, setShowEdit] = useState(false);
  const [anchorSettings, setAnchorSettings] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmHide, setConfirmHide] = useState(false);
  const [showPromote, setShowPromote] = useState(false);

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setAnchorSettings(event.currentTarget);
  };
  const handleCloseMenu = (event) => {
    event.stopPropagation();
    setAnchorSettings(null);
  };

  const onItemSelect = (option) => (event) => {
    event.stopPropagation();
    setAnchorSettings(null);

    if (option === "edit") {
      setShowEdit(true);
    }
    if (option === "delete") {
      setConfirmDelete(true);
    }
    if (option === "disable") {
      setConfirmHide(true);
    }
    if (option === "editCodes") {
      navigate(`/productCode/${productId}`);
    }
    if (option === "promote") {
      setShowPromote(true);
    }
  };

  const deleteProduct = async () => {
    const response = await deleteProductSeller(productId);
    if (response.data === "deleted") {
      setConfirmDelete(false);
      onDelete?.();
    }
  };

  const hideProduct = async () => {
    const response = await disableProduct(productId);
    if (response?.data) {
      setConfirmHide(false);
      onHide?.();
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleCloseMenu}>
        <Box>
          <Button
            type="button"
            variant="outlined"
            className={styles.menuDots}
            onClick={handleOpenMenu}
          >
            <MenuDots />
          </Button>

          <Menu
            data-mui-color-scheme={theme.palette.mode}
            className={styles.userMenu}
            classes={{
              paper: styles.userMenu__paper,
              list: styles.userMenu__list,
            }}
            anchorEl={anchorSettings}
            open={Boolean(anchorSettings)}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {ProductMenuOptions.filter(({ label }) =>
              isHidden ? label !== "disable" : true
            ).map(({ label, icon, lightIcon }) => (
              <MenuItem
                key={label}
                className={styles.userMenu__item}
                onClick={onItemSelect(label)}
              >
                <Typography className={styles.userMenu__anchor}>
                  {theme.palette.mode === "dark" ? icon : lightIcon}{" "}
                  {translate(`products.product.menu.${label}`)}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </ClickAwayListener>

      <AddProductModal
        open={showEdit}
        productId={productId}
        close={() => setShowEdit(false)}
      />

      <DeleteConfirmContent
        open={confirmDelete}
        close={() => setConfirmDelete(false)}
        confirm={deleteProduct}
      />

      <HideItemConfirmContent
        open={confirmHide}
        close={() => setConfirmHide(false)}
        confirm={hideProduct}
      />

      <PromoteProductContent
        open={showPromote}
        close={() => setShowPromote(false)}
        productId={productId}
        productName={productName}
      />
    </>
  );
};

export default MenuOptions;
