import { useTheme } from "@emotion/react";
import { Box, Button, Card, Typography, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { CURRENCY_SIGN_BY_CURRENCY } from "../../constants";
import { useTranslate } from "../../context/translate/translate.context";
import {
  isoToReadableDate,
  isoToReadableHour,
} from "../../lib/isoToReadableDate";
import { TranslateText } from "../translate-text";
import styles from "./styles.module.css";

const SalesCard = ({ order, openTransactions }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { translate } = useTranslate();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box className={styles.containerWrapper}>
      <Card className={styles.container}>
        <Box className={styles.product}>
          <img
            src={order?.product?.thumb && order?.product?.thumb?.length > 0 ? 
              order?.product?.thumb?.[0]
              :
              order?.product?.media?.[0] || "defaultImage.png"
            }
            alt="Product image"
            className={styles.productImage}
          />

          <Box className={styles.productInfo}>
            {isMobile && (
              <Box className={styles.category}>
                <img
                  src={order?.product?.category?.thumb ? 
                    order?.product?.category?.thumb
                    :
                    order?.product?.category?.media || "defaultImage.png"
                  }
                  alt="Category image"
                  className={styles.categoryImage}
                />

                <Box className={styles.categoryNameContainer}>
                  <Typography className={styles.categoryName}>
                    {order?.product?.category?.name}
                  </Typography>
                  <Typography className={styles.categoryProductType}>
                    {order?.product?.category?.primaryProductType?.name}
                  </Typography>
                </Box>
              </Box>
            )}

            <Typography className={styles.productTitle}>
              {order?.product?.name}
            </Typography>
            <Typography className={styles.productQuantity}>
              {order?.productsInfo.quantity > 1
                ? translate("userProfile.purchases.count.total").replace(
                    "$count",
                    order.productsInfo.quantity
                  )
                : translate("userProfile.purchases.count.total1")}
            </Typography>

            <Box className={styles.paidTag}>
              <Typography>
                <TranslateText value="userProfile.purchases.paid" />
              </Typography>
            </Box>
          </Box>
        </Box>

        {!isMobile && (
          <Box className={styles.category}>
            <img
              src={order?.product?.category?.thumb ? 
                order?.product?.category?.thumb
                :
                order?.product?.category?.media || "defaultImage.png"
              }
              alt="Category image"
              className={styles.categoryImage}
            />

            <Box className={styles.categoryNameContainer}>
              <Typography className={styles.categoryName}>
                {order?.product?.category?.name}
              </Typography>
              <Typography className={styles.categoryProductType}>
                {order?.product?.category?.primaryProductType?.name}
              </Typography>
            </Box>
          </Box>
        )}

        <Box className={styles.orderPrice}>
          <Typography className={styles.orderPriceTitle}>
            <TranslateText value="userProfile.purchases.orderPrice" />
          </Typography>
          <Typography className={styles.orderPriceValue}>
            {CURRENCY_SIGN_BY_CURRENCY[order?.product?.currency || "USD"]}{" "}
            {(parseFloat((
              (order?.product?.price -
                (order?.product?.price * (order?.product?.promocode?.discount || 0)) / 100.0) *
              order.productsInfo.quantity
            ).toFixed(2)))}
          </Typography>
        </Box>

        <Box className={styles.orderTime}>
          <Typography className={styles.orderDate}>
            {isoToReadableDate(order?.transaction.createdAt).replaceAll(
              "/",
              "."
            )}
          </Typography>
          <Typography className={styles.orderHour}>
            {isoToReadableHour(order?.transaction.createdAt)}
          </Typography>
        </Box>

        <Box className={styles.buttonsContainer}>
          <Button
            variant="contained"
            className={styles.orderDetails}
            onClick={() => navigate("/order/" + order._id)}
          >
            <TranslateText value="userProfile.purchases.orderDetails" />
          </Button>
          <Button
            variant="outlined"
            className={styles.viewTransactions}
            onClick={openTransactions}
          >
            <TranslateText value="userProfile.purchases.viewTransactions" />
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default SalesCard;
