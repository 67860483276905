import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { deleteMultipleImages } from "../../../../api/images/deleteMultipleImages";
import { uploadImage } from "../../../../api/images/uploadImage";
import { createProduct } from "../../../../api/product/addProduct";
import { getProductByIdToEdit } from "../../../../api/product/getProductById";
import { updateProduct } from "../../../../api/product/updateProduct";
import { useTranslate } from "../../../../context/translate/translate.context";
import { regions } from "../../../../lib/regions";
import MentupModal from "../../../MentupModal/MentupModal";
import MentupStepper from "../../../MentupStepper";
import AddProductModalStep1 from "./components/step1";
import AddProductModalStep2 from "./components/step2";
import AddProductModalStep3 from "./components/step3";
import AddProductModalStep4 from "./components/step4";
import AddProductModalStep5 from "./components/step5";
import AddProductModalSuccess from "./components/stepSuccess";
import styles from "./styles.module.css";
import AlertComponent from "../../../AlertComponent";
import { useNavigate } from "react-router-dom";

const AddProductModal = ({ productId, open, close }) => {
  const defaultStepsCount = 3;
  const { translate } = useTranslate();

  const settings = localStorage.getItem("userSettings");
  const userRedux = useSelector((state) => state.user);

  const navigate = useNavigate()

  const country =
    (settings && JSON.parse(settings)?.country) || userRedux?.country;

  const [alert, setAlert] = useState(null);
  const [step, setStep] = useState(0);
  const [images, setImages] = useState([]);
  const [editImages, setEditImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [maxQuantity, setMaxQuantity] = useState(false);
  const [addProductCodes, setAddProductCodes] = useState(false);
  const [addCharacteristics, setAddCharacteristics] = useState(false);
  const [product, setProduct] = useState({
    category: null,
    codes: [],
    commissionPaidBySeller: false,
    customCharacteristics: [],
    description: "",
    name: "",
    price: "",
    productChars: [],
    promocode: "",
    quantity: "",
    region: null,
    server: null,
    software: [],
    type: null,
    typeAttribute: [],
    attributes: {},
  });

  const getProduct = async (id) => {
    const response = await getProductByIdToEdit(id);
    setProduct((prev) => ({
      ...prev,
      ...response.data,
      promocode: response.data.promocode?.discount ?? null,
      region: regions.find((r) => r.name === response.data.region),
    }));
    setImages(response.data.media);
    setEditImages(response.data.media);
  };

  useEffect(() => {
    setStep(open ? 1 : 0);
  }, [open]);

  useEffect(() => {
    if (productId && open) {
      getProduct(productId);
    }
  }, [productId, open]);

  const displayStepsCount = useMemo(
    () =>
      defaultStepsCount +
      (addProductCodes ? 1 : 0) +
      (addCharacteristics ? 1 : 0),
    [defaultStepsCount, addProductCodes, addCharacteristics]
  );

  const createNewProduct = async (productData) => {
    const v = {
      category: productData.category._id,
      codes: productData.codes,
      commissionPaidBySeller: productData.commissionPaidBySeller,
      customCharacteristics: productData.customCharacteristics,
      description: productData.description,
      disabled: false,
      media: productData.media,
      thumb: productData.thumb,
      name: productData.name,
      price: productData.price,
      productChars: productData.productChars,
      promocode: productData.promocode,
      quantity: productData.quantity,
      region: productData.region.name,
      server: productData.server._id,
      software: productData.software.map(({ _id }) => _id),
      type: productData.type._id,
      typeAttribute: productData.typeAttribute,
      attributes: productData.attributes,
    };

    try {
      const response = await createProduct(v, country);

      setProduct(response.data);
      setStep(6);
    } catch (error) {
      //console.error(error);
      setAlert({
        message:
          error.response?.data?.message ||
          translate("errorMessages.unexpectedError"),
        severity: "error",
      });
    }
  };

  const editOldProduct = async (productData) => {
    const v = {
      disabled: false,

      /** step 1 */
      name: productData.name,
      description: productData.description,
      software: productData.software.map(({ _id }) => _id),
      category: productData.category._id,
      type: productData.type._id,
      typeAttribute: productData.typeAttribute,
      attributes: productData.attributes,
      server: productData.server._id,

      /** step 2 */
      price: productData.price,
      quantity: productData.quantity,
      promocode: productData.promocode,
      region: productData.region.name,
      commissionPaidBySeller: productData.commissionPaidBySeller,

      media: productData.media,
      thumb: productData.thumb,
    };

    const response = await updateProduct(v, productId);

    setProduct(response.data);
    setStep(6);
  };

  const uploadImages = async (v) => {
    const media = [];
    const thumb = [];

    for (let i = 0; i < v.length; i++) {
      const { pictureFile } = v[i];

      const formData = new FormData();
      formData.append("image", pictureFile);
      const res = await uploadImage(formData);

      if (res.data?.media) {
        media.push(res.data.media);
      }
      if (res.data?.thumb) {
        thumb.push(res.data.thumb);
      }
    }
    return {
      media,
      thumb,
    };
  };

  const submit = useCallback(
    async (v) => {
      setLoading(true);

      try {
        if (productId) {
          const imagesToDelete = editImages.filter(
            ({ pictureId }) => !v.some(({ pictureId: id }) => id === pictureId)
          );
          await deleteMultipleImages(
            imagesToDelete.map(({ pictureId }) => pictureId)
          );
          const images = await uploadImages(
            v.filter(({ pictureFile }) => !!pictureFile)
          );
          await editOldProduct({
            ...product,
            quantity: maxQuantity ? 1000 : product.quantity,
            media: [
              ...editImages
                .filter(({ pictureId }) =>
                  v.some(({ pictureId: id }) => id === pictureId)
                )
                .map(({ pictureId }) => pictureId),
              ...images.media,
            ],
            thumb: [
              ...editImages
                .filter(({ pictureId }) =>
                  v.some(({ pictureId: id }) => id === pictureId)
                )
                .map(({ pictureId }) => pictureId + "_thumbnail"),
              ...images.thumb,
            ],
          });
        } else {
          const images = await uploadImages(v);
          await createNewProduct({
            ...product,
            quantity: maxQuantity ? 1000 : product.quantity,
            media: images.media,
            thumb: images.thumb,
          });
        }
      } catch (e) {
        navigate('/404')
      } finally {
        setLoading(false);
      }
    },
    [productId, product, maxQuantity]
  );

  const changeStep = useCallback(
    (stepCount) => {
      const steps = [
        1,
        2,
        addProductCodes && 3,
        addCharacteristics && 4,
        5,
      ].filter(Boolean);
      setStep(steps[steps.indexOf(step) + stepCount]);
    },
    [step, addProductCodes, addCharacteristics]
  );

  const currentStepStepper = useMemo(() => {
    const steps = [
      1,
      2,
      addProductCodes && 3,
      addCharacteristics && 4,
      5,
    ].filter(Boolean);
    return steps.indexOf(step);
  }, [step, addProductCodes, addCharacteristics]);

  return (
    <MentupModal
      open={open}
      close={close}
      sx={{
        maxWidth: "515px",
        width: "100%",
      }}
    >
      <AlertComponent
        message={alert?.message}
        severity={alert?.severity || "error"}
        resetMessageInParent={() => setAlert(null)}
      />

      <Box className={styles.confirmContainer}>
        <Box className={styles.headerContainer}>
          <Typography className={styles.title}>
            {translate("products.product.create.title")}
          </Typography>
        </Box>

        {step !== 6 && (
          <Box sx={{ width: "100%", maxWidth: "310px" }}>
            <MentupStepper
              getStepTitle={(index) =>
                `${translate("products.product.promote.steps.stepper")} ${
                  index + 1
                }`
              }
              current={currentStepStepper}
              steps={displayStepsCount}
            />
          </Box>
        )}

        {step === 1 && (
          <AddProductModalStep1
            defaultProduct={product}
            submit={(v) => {
              setProduct((prev) => ({ ...prev, ...v }));
              changeStep(+1);
              console.log(product)
            }}
          />
        )}
        {step === 2 && (
          <AddProductModalStep2
            defaultProduct={product}
            maxQuantity={maxQuantity}
            addProductCodes={addProductCodes}
            addCharacteristics={addCharacteristics}
            setMaxQuantity={setMaxQuantity}
            setAddProductCodes={setAddProductCodes}
            setAddCharacteristics={setAddCharacteristics}
            submit={(v) => {
              setProduct((prev) => ({ ...prev, ...v }));
              changeStep(+1);
            }}
            back={(v) => {
              setProduct((prev) => ({ ...prev, ...v }));
              changeStep(-1);
            }}
          />
        )}
        {step === 3 && (
          <AddProductModalStep3
            defaultProduct={{
              ...product,
              quantity: maxQuantity ? 1000 : product.quantity,
            }}
            submit={(v) => {
              setProduct((prev) => ({ ...prev, codes: Object.values(v) }));
              changeStep(+1);
            }}
            back={(v) => {
              setProduct((prev) => ({ ...prev, ...v }));
              changeStep(-1);
            }}
          />
        )}
        {step === 4 && (
          <AddProductModalStep4
            defaultProduct={product}
            submit={(v) => {
              setProduct((prev) => ({ ...prev, ...v }));
              changeStep(+1);
            }}
            back={(v) => {
              setProduct((prev) => ({ ...prev, ...v }));
              changeStep(-1);
            }}
          />
        )}
        {step === 5 && (
          <AddProductModalStep5
            defaultImages={images}
            loading={loading}
            submit={submit}
            back={(v) => {
              setImages(v);
              changeStep(-1);
            }}
          />
        )}
        {step === 6 && (
          <AddProductModalSuccess product={product} close={close} />
        )}
      </Box>
    </MentupModal>
  );
};

export default AddProductModal;
