import {
  Avatar,
  Box,
  Card,
  Chip,
  Divider,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslate } from "../../context/translate/translate.context";
import { priceLabel } from "../../utils/strings/price";
import GlobalIcon from "../svg/Global";
import PlayStation from "../svg/platforms/PlayStation";
import Steam from "../svg/platforms/Steam";
import Xbox from "../svg/platforms/Xbox";
import { TranslateText } from "../translate-text";
import MenuOptions from "./components/MenuOptions/MenuOptions";
import styles from "./styles.module.css";

const platforms = {
  steam: <Steam />,
  xbox: <Xbox />,
  playstation: <PlayStation />,
};

const ProductCard = ({
  variant = "small",
  product: defaultProduct,
  onDelete,
  hideOptions = false,
  isMobile
}) => {
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [product, setProduct] = useState(defaultProduct);
  const {
    _id,
    category,
    user,
    media,
    name,
    description,
    price,
    isFeatured,
    region,
    promocode,
    reviews,
    slug,
    software,
    soldOut,
    disabled,
    type,
    thumb
  } = product;
  const isCurrentUser = currentUser?.userSlug === user.userSlug;

  const { translate } = useTranslate();

  const theme = useTheme();

  const navigate = useNavigate();

  const handleRedirect = (href) => (event) => {
    event.stopPropagation();

    if (href) {
      navigate(href);
    }
  };

  const onDisable = () => {
    setProduct((prev) => ({
      ...prev,
      disabled: true,
    }));
  };

  const renderReviewBlock = (reviews = []) => {
    const averageRating =
      (
        reviews.length &&
        reviews.reduce((accumulator, { rating }) => accumulator + rating, 0) /
          reviews.length
      ).toFixed(1) || 0;

    return (
      <Box
        className={clsx(
          styles.reviews,
          (soldOut || disabled) && styles.opacity50
        )}
      >
        <Tooltip
          title={translate("userProfile.rating.average", {
            rating: averageRating,
          })}
          placement="left"
          arrow
        >
          <Box
            style={{ "--percent": `${(averageRating / 5) * 100}%` }}
            className={styles.reviews__rating}
            min={0}
            max={5}
            value={averageRating}
            component="meter"
          />
        </Tooltip>
        <Typography className={styles.reviews__text}>
          {reviews.length}{" "}
          {translate(
            `userProfile.rating.count.${reviews.length === 1 ? "one" : "other"}`
          )}
        </Typography>
      </Box>
    );
  };

  const availablePlatforms = !!software?.length && (
    <Box className={styles.platforms}>
      {/*
      <Typography className={styles.platforms__text}>
        {translate("products.card.availableOn")}:
      </Typography>
      */}
      
      <Box className={styles.platforms__icons}>
        {/*
        {software.map(({ icon, name }) => {
          if (!platforms[icon]) {
            return (
              <Tooltip key={name} title={name}>
                {name?.slice(0, 2)}
              </Tooltip>
            );
          }

          return <Fragment key={name}>{platforms[icon]}</Fragment>;
        })} 

        {(region && variant === "small" )&& (
          <Tooltip title={`Region: ${region}`} placement="top">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <GlobalIcon />
            </Box>
          </Tooltip>
        )}
          */}
      </Box>
    </Box>
  );

  const userBlock = (
    <Box
      className={styles.userBlock}
      onClick={handleRedirect(user.userSlug && `/profile/${user.userSlug}`)}
    >
      <Avatar className={styles.avatar} alt="User avatar" src={user.avatar} />
      <Box>
        <Typography className={styles.username}>{user.username}</Typography>
        {renderReviewBlock(user.reviews)}
      </Box>
    </Box>
  );

  const imageSource = thumb && thumb.length > 0 
    ? Array.isArray(thumb) ? thumb[0] : thumb 
    : media && media.length > 0 
      ? Array.isArray(media) ? media[0] : media 
      : '/path/to/default/image.jpg';

  const renderUserContent = () => {
    if (isCurrentUser && !hideOptions) {
      return (
        <MenuOptions
          productId={_id}
          productName={name}
          isHidden={disabled}
          onDelete={onDelete}
          onHide={onDisable}
        />
      );
    }

    return (
      <Tooltip
        classes={{
          popper: clsx(
            styles.header__tooltip,
            theme.palette.mode === "light" && styles.header__tooltip_dark
          ),
        }}
        title={
          <Box
            data-mui-color-scheme={
              theme.palette.mode === "light" ? "dark" : "light"
            }     
          >
            {userBlock}
          </Box>
        }
        placement="top"
        arrow
      >
        <Avatar
          onClick={handleRedirect(user.userSlug && `/profile/${user.userSlug}`)}
          className={styles.avatar}
          alt="User avatar"
          src={user.avatar}
        />
      </Tooltip>
    );
  };

  return (
    <Box
      className={clsx(
        styles.containerWrapper,
        isFeatured && styles.containerWrapperFeatured,
        (variant === "large" || isMobile) && styles.containerWrapper_width
      )}
    >
      <Card
        className={clsx(
          styles.container,
          variant === "large" && styles.container_large
        )}
        onClick={handleRedirect(
          slug && user.userSlug && `/profile/${user.userSlug}/${slug}`
        )}
      >
        {variant === "small" && (
          <Box
            className={clsx(
              styles.header,
              (soldOut || disabled) && styles.opacity50
            )}
          >
            <Box
              className={styles.categoryImage}
              component="img"
              src={category.thumb !== null && category.thumb !== undefined ? category.thumb : category.media}
              loading="lazy"
            />
            <Box className={styles.categoryTextContainer}>
              <Typography className={styles.categoryTitle} variant="h4">
                {category.name}
              </Typography>
              <Typography className={styles.categoryType}>
                {type?.name}
              </Typography>
            </Box>
            {renderUserContent()}
          </Box>
        )}
        <Box className={styles.productImageContainer}>
          <Box
            className={clsx(
              styles.productImage,
              (soldOut || disabled) && styles.opacity50
            )}
            component="img"
            src={imageSource}
            loading="lazy"
          />

          {(soldOut || disabled) && (
            <Box className={styles.inactiveContainer}>
              <Box className={styles.inactive}>
                <Typography className={styles.inactiveTitle}>
                  <TranslateText
                    value={
                      soldOut
                        ? "products.product.soldOut"
                        : disabled
                        ? "products.product.disabled"
                        : ""
                    }
                  />
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Tooltip
          className={clsx((soldOut || disabled) && styles.opacity50)}
          title={name.length > 25 ? name : ""}
          placement="top"
          arrow
        >
          <Typography className={styles.productTitle} variant="h5">
            {name}
          </Typography>
        </Tooltip>
        {/*
        {description && (
          <Tooltip title={description.length > 45 ? description : ""} arrow>
            <Typography
              className={clsx(
                styles.productDescription,
                (soldOut || disabled) && styles.opacity50
              )}
            >
              {description}
            </Typography>
          </Tooltip>
        )} */}
        {variant === "small" && renderReviewBlock(reviews)}
        {variant === "large" && (
          <>
            <Box
              className={(soldOut || disabled) && styles.opacity50}
              sx={{ display: "flex" }}
            >
              {userBlock}
              {availablePlatforms}
            </Box>
            <Box
              className={clsx(
                styles.chipContainer,
                (soldOut || disabled) && styles.opacity50
              )}
            >
              <Chip
                className={styles.chip}
                label={type?.name}
                onClick={handleRedirect(`/games/${category.slug}`)}
              />

              {/*
              {category.productTypes.map(({ name, slug }, index) => (
                <Chip
                  className={styles.chip}
                  key={`${name}${index}`}
                  label={name}
                  onClick={handleRedirect(`/games/${category.slug}`)}
                />
              ))}
              */}
            </Box>
            <Divider />
          </>
        )}
        <Box
          className={clsx(
            styles.footer,
            variant === "small" && styles.footer_align,
            (soldOut || disabled) && styles.opacity50
          )}
        >
          <Box className={styles.footer__leftContainer}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Typography className={styles.price} sx={{
              color: theme.palette.mode === 'dark' ? 'primary.yellow' : 'primary.main'
            }}>
              {promocode ? (
                <>
                  {priceLabel(price - (price * promocode.discount) / 100.0)}
                  <Box className={styles.price__strike} component="span"
                    sx={{
                      marginLeft: '10px'
                    }}>
                    {priceLabel(price)}
                  </Box>
                  
                </>
              ) : (
                priceLabel(price)
              )}
            </Typography>
            {promocode && (
              <Chip
                className={styles.promocode}
                label={`-${promocode.discount}%`}
              />
            )}
            </Box>
            
            {variant === "small" && availablePlatforms}
            
          </Box>
          <Box
            className={clsx(
              styles.footer__rightContainer,
              (soldOut || disabled) && styles.opacity50
            )}
          >
            {/* TODO: show flag for region locked items */}
            
            
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default ProductCard;
